/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiSummaryResponse } from './AiSummaryResponse';
import { request as __request } from '@src/api/core/request';
import axios from 'axios';

export class AiSummaryControllerService {

    public static async getAiSummary({
        keywordHash,
    }: {
        keywordHash: string,
    }): Promise<AiSummaryResponse> {
        const response = await axios.get(
            `https://summary-api.signal.bz/summary/${keywordHash}.json`,
            {
                withCredentials: false,
            }
        );
        return response.data;
    }
}
