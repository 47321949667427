import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoBookmarkFill = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.496 7.77a.356.356 0 0 1-.6.232L4.943 6.154a.643.643 0 0 0-.884 0L2.103 8.002a.356.356 0 0 1-.599-.233 46.8 46.8 0 0 1-.03-6.107c.04-.667.598-1.18 1.267-1.18h3.518c.669 0 1.228.513 1.268 1.18.12 2.034.114 4.073-.031 6.107Z"
      fill="#727CFC"
      stroke="#727CFC"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgIcoBookmarkFill;
