export const APP_NAME = 'MARKET.DOCTOR';

export const APP_HOST = process.env.REACT_APP_HOST;
export const APP_PREFIX_PATH = '/app';
export const EXTERNAL_PATH = '/external';

export const APP_HOME_PATH = APP_PREFIX_PATH + '/analysis/main';

export const MAIN_PATH = '/main';

export const ABOUT_US_URL = process.env.REACT_APP_ABOUT_US_URL ?? '';

export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL ?? '';
export const KEYWORD_API_URL = process.env.REACT_APP_API_URL ?? '';
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID ?? '';
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET ?? '';
export const KAKAO_JAVASCRIPT_KEY = process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY;
export const KAKAO_REDIRECT_URL =
  process.env.REACT_APP_PUBLIC_URL + '/auth/oauth/callback';
export const KAKAO_REDIRECT_URL_COMMAND =
  process.env.REACT_APP_PUBLIC_URL + '/auth/oauth/callback/command';

//THEME
export const ROW_GUTTER = 16;
export const SIDE_NAV_WIDTH = 250;
export const SIDE_NAV_COLLAPSED_WIDTH = 80;
export const LIGHT_THEME = 'light';
export const DARK_THEME = 'dark';

export const DEFAULT_THEME = LIGHT_THEME;

//local storage
export const LOCAL_STORAGE_THEME_KEY = 'keywords-local-storage-theme';
export const LOCAL_STORAGE_KEYWORD_NEWS_KEY =
  'keywords-local-storage-keyword-news';
export const LOCAL_STORAGE_KEYWORD_NEWS_ITEM_KEY =
  'keywords-local-storage-keyword-news-item';

//Date
export const DATE_FORMAT_YYYYMMdd = 'YYYYMMdd';
export const DATE_FORMAT_YYYYMMDD = 'YYYYMMDD';
export const DATE_FORMAT_YYYY_MM_dd = 'YYYY-MM-dd';
export const DATE_FORMAT_YYYY_MM_dd_Combined = 'YYYYMMddHHmm';
export const DATE_FORMAT_YYYY_MM_dd_HH_mm = 'YYYY-MM-dd HH:mm';
export const DATE_FORMAT_DD_MM_YYYY = 'DD-MM-YYYY';
export const DATE_FORMAT_DD_MM_YYYY_WITH_DOT = 'DD.MM.YYYY';
export const DATE_FORMAT_mm_dd_YYYY_WITH_SLASH = 'MM/dd/YYYY';
export const DATE_FORMAT_m_d_YYYY_WITH_SLASH = 'M/d/YYYY';
export const DATE_FORMAT_DD_MM_YYYY_WITH_SLASH = 'DD/MM/YYYY';
export const DATE_FORMAT_dd_MMM = 'ddMMM';
export const DATE_FORMAT_dd_MM_yy = 'ddMMyy';
export const DATE_FORMAT_dd_MMM_YYYY = 'ddMMMYYYY';
export const DATE_FORMAT_DD_MM_YYYY_COMBINED = 'DDMMYYYY';
export const DATE_FORMAT_DD_MM_YYYY_HH_mm_ss = "YYYY-MM-dd'T'HH:mm:ss";
export const DATE_FORMAT_DD_MM_YYYY_HH_mm_ss_sz = "YYYY-MM-dd'T'HH:mm:ss.SSS";
export const DATE_FORMAT_DD_MM_YYYY_HH_mm = 'dd-MM-YYYY HH:mm';
export const DATE_FORMAT_HH_mm = 'HH:mm';
export const DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ = "YYYY-MM-DD'T'HH:mm:ssZ";

export const KO_DATE_FORMAT_YYYY_MM_DD_HH_mm = 'YYYY년 MM월 DD일 HH:mm';
export const KO_DATE_FORMAT_YYYY_MM_DD = 'YYYY년 MM월 DD일 HH:mm';

export const KEYWORD_NEWS_TYPE = {
  NAVER_NEWS: 'NaverNews' as const,
  NAVER_BLOG: 'NaverBlog' as const,
  NAVER_CAFE: 'NaverCafe' as const,
  YOUTUBE: 'Youtube' as const,
  INSTAGRAM: 'Instagram' as const,
};

export const KEYWORD_NEWS_ACTION_TYPE = {
  INSERT: 'INSERT',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

export const KEYWORD_NEWS_SAVE_MAX_COUNT = 5;
export const KEYWORD_NEWS_LIST_DISPLAY_COUNT = 10;
export const KEYWORD_NEWS_SEARCH_ITEM_MAX_COUNT = 100;

export const API_SUCCESS_CODE = 'OK';
