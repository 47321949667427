import { PaymentMethodItem, TeambinderApiService } from '@src/api';
import { IcoTrash } from '@src/assets/svg';
import { useMutation, useQuery } from '@tanstack/react-query';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { Button, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const { confirm, info } = Modal;
const columns: ColumnsType<PaymentMethodItem> = [
  {
    title: '카드 번호',
    dataIndex: 'card',
    render: (_, { cardCompany, number }) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontSize: '14px', fontWeight: 800 }}>
            ({cardCompany}) {number}
          </span>
          <span style={{ color: '#89949F', fontSize: '12px' }}>
            {/* 카드 만료일: 2022-11-10 */}
          </span>
        </div>
      );
    },
  },
  // {
  //   title: '',
  //   dataIndex: 'status',
  //   render: (_, { activated }) =>
  //     activated ? (
  //       <div
  //         style={{
  //           width: '49px',
  //           height: '24px',
  //           color: 'white',
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //           borderRadius: '16px',
  //           backgroundColor: '#24C185',
  //           fontSize: '12px',
  //         }}
  //       >
  //         사용중
  //       </div>
  //     ) : null,
  // },
  {
    title: '',
    dataIndex: 'remove',
    key: 'remove',
    render: (value, record) => {
      const handleRemove = () => {
        confirm({
          title: '카드를 삭제하시겠습니까?',
          okText: '삭제',
          cancelText: '취소',
          onOk: async () => {
            if (record.paymentMethodId) {
              await TeambinderApiService.deletePaymentMethod({
                paymentMethodId: record.paymentMethodId,
              });
              window.location.reload();
            }
          },
        });
      };

      return (
        <Button
          style={{
            cursor: 'pointer',
            marginRight: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '36px',
            float: 'right',
            height: '36px',
            borderRadius: '4px',
            border: '1px solid #FB5E54',
          }}
          onClick={handleRemove}
          icon={<IcoTrash width={14} height={18} fill="#FB5E54" />}
        />
      );
    },
  },
];

const SettingPaymentCards = () => {
  const customerKeyQuery = useQuery(
    ['customerKey'],
    TeambinderApiService.getCustomerKey,
  );
  const customerKey = customerKeyQuery.data?.data?.value;

  const { mutateAsync: requestBillingKey } = useMutation<
    unknown,
    unknown,
    { requestBody: { customerKey: string; authKey: string } }
  >(TeambinderApiService.success);
  const cardList = useQuery(
    ['cardList'],
    TeambinderApiService.findPaymentMethods,
  );
  const addNewCard = async (redirectToCardPage = false, plan = '') => {
    if (!customerKey) {
      info({
        title: '카드 등록에 실패했습니다.',
      });
      return;
    }

    const toss = await loadTossPayments(
      process.env.REACT_APP_TOSS_PAYMENTS_CLIENT_KEY || '',
    );
    toss.requestBillingAuth('카드', {
      customerKey,
      successUrl: `${
        process.env.REACT_APP_PUBLIC_URL
      }/app/my?tab=4&status=success&plan=${plan}&redirectToCardPage=${String(
        redirectToCardPage,
      )}`,
      failUrl: `${process.env.REACT_APP_PUBLIC_URL}/app/my?tab=4&status=fail`,
    });
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const loadToss = searchParams.get('loadToss');
  const plan = searchParams.get('plan');
  useEffect(() => {
    if (loadToss === 'true') {
      navigate('/app/my?tab=4', { replace: true });
      addNewCard(true, plan || '');
    }
  }, [loadToss]);

  const cardRegistrationSuccess = searchParams.get('status');
  useEffect(() => {
    const authKey = searchParams.get('authKey');
    const customerKeyFromParams = searchParams.get('customerKey');
    const redirectToCardPage = searchParams.get('redirectToCardPage');
    if (!authKey || !customerKeyFromParams) {
      return;
    }

    if (cardRegistrationSuccess === 'success') {
      requestBillingKey(
        { requestBody: { customerKey: customerKeyFromParams, authKey } },
        {
          onSuccess: () => {
            confirm({
              title: '카드가 등록되었습니다.',
              okText: '확인',
              onOk: async () => {
                cardList.refetch();
                if (redirectToCardPage === 'true') {
                  navigate(`/app/my?tab=2&selectedPlan=${plan}`, {
                    replace: true,
                  });
                  return;
                }
                navigate('/app/my?tab=4', { replace: true });
              },
              cancelButtonProps: { style: { display: 'none' } },
            });
          },
          onError: () => {
            info({
              title: '카드 등록에 실패했습니다.',
            });
          },
        },
      );
    } else {
      info({
        title: '카드 등록에 실패했습니다.',
      });
    }
  }, [cardRegistrationSuccess]);

  return (
    <div style={{ position: 'relative' }}>
      {cardList.isLoading ? null : (
        <Table
          locale={{ emptyText: '등록된 카드가 없습니다.' }}
          columns={columns}
          dataSource={cardList.data?.data}
          pagination={false}
        />
      )}
      {cardList.data?.data?.length === 0 && (
        <Button
          onClick={() => addNewCard()}
          style={{
            fontSize: '14px',
            borderRadius: '12px',
            backgroundColor: '#24C185',
            height: '42px',
            color: 'white',
            marginTop: '20px',
            position: 'absolute',
            right: '8px',
          }}
        >
          + 결제 카드 추가
        </Button>
      )}
    </div>
  );
};

export default SettingPaymentCards;
