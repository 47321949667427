import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Input,
  InputRef,
  Modal,
  Row,
  Spin,
  Typography,
} from 'antd';
import { useKakaoLogin } from '@hooks/auth';
import { SearchOutlined } from '@ant-design/icons';
import { useSignInToken } from '@hooks/auth/useFirebase';
import { useMutationKakaoLogin } from '@hooks/api/auth';
import { BasicResponseMarketdoctorAccessToken } from '@src/api';
import {
  API_SUCCESS_CODE,
  APP_HOME_PATH,
  KAKAO_REDIRECT_URL,
  MAIN_PATH,
} from '@src/constants/constant';
import { useNavigate } from 'react-router-dom';
import { LoadingFull } from '@components/shared';
import KakaoLogin from '@views/main/KakaoLogin';
import { Logo } from '@components/layout';
import { useAtom } from 'jotai';
import { authAtom } from '@src/store/auth';

const { Title } = Typography;
const { confirm } = Modal;

const backgroundStyle = {
  backgroundImage: 'url(/img/others/img-17.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

interface LoginFormProps {
  showLoading?: boolean;
  extra?: string | ReactElement;
  loading?: boolean;
  showMessage?: string;
  message?: string;
}

const Login = (props: LoginFormProps) => {
  const navigate = useNavigate();
  const [authInfo, setAuthInfo] = useAtom(authAtom);

  const signIn = useSignInToken();

  const [kakaoLoginCode, setKakaoLoginCode] = useState(() => {
    const params = new URL(window.location.href).searchParams;
    //params에 저장된 파라미터 안에서 'code'의 값을 가져옴
    const code = params.get('code');

    return code;
  });
  const [isLoading, setLoading] = useState(false);
  const kakaoSigin = useMutationKakaoLogin({
    options: {
      onSuccess: async (response: BasicResponseMarketdoctorAccessToken) => {
        setAuthInfo(response.data);
        navigate('/app');
      },
      onError: (error: any) => {
        confirm({
          type: 'error',
          title: '오류',
          content: '로그인에 실패 했습니다. 다시 시도해 주세요.',
          okText: '확인',
          cancelText: '취소',
          onOk() {
            window.localStorage.removeItem('auth-info');
            const authRedirecrtUrl = KAKAO_REDIRECT_URL;
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=7fa35d903c130394963af980d96edd38&redirect_uri=${authRedirecrtUrl}&response_type=code&prompt=login`;
          },
          onCancel() {
            window.localStorage.removeItem('auth-info');
            const authRedirecrtUrl = KAKAO_REDIRECT_URL;
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=7fa35d903c130394963af980d96edd38&redirect_uri=${authRedirecrtUrl}&response_type=code&prompt=login`;
          },
        });
      },

      onSettled: () => {
        console.log('onSettled');
        setLoading(false);
      },
    },
  });

  useEffect(() => {
    if (!kakaoLoginCode) {
      confirm({
        title: '안내',
        content: '잘못된 접근 입니다.',
        okText: '확인',
        okCancel: false,
        onOk() {
          navigate(MAIN_PATH);
        },
      });

      return;
    }

    setLoading(true);
    kakaoSigin.mutate({
      code: kakaoLoginCode,
      redirectUri: KAKAO_REDIRECT_URL,
    });
  }, [kakaoLoginCode]);

  return (
    <div className={'login-container'}>
      <div className={'login-wrap'}>
        <Logo mobileLogo={false} />
        <div className={'text'}>로그인 중입니다...</div>
        <Spin></Spin>
      </div>
    </div>
  );
};

export default Login;
