import {
  ApiService,
  PaymentHistoryItem,
  TeambinderApiService,
  UserSubscription,
} from '@src/api';
import { useQuery } from '@tanstack/react-query';
import { Button, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';

const { confirm, info } = Modal;

const columns: ColumnsType<Partial<PaymentHistoryItem>> = [
  {
    title: '플랜',
    dataIndex: 'planName',
    render: (value, record) => {
      if (record.status === 'CANCELLED') {
        return `${
          record.planName
        } (취소됨, ${record.refundedAmount?.toLocaleString()}원 환불 완료)`;
      }
      return record.planName || '';
    },
  },
  {
    title: '구독 시작',
    dataIndex: 'subscriptionStartAt',
  },
  {
    title: '구독 종료',
    dataIndex: 'subscriptionEndAt',
  },
  {
    title: <div style={{ textAlign: 'right' }}>구독료</div>,
    render: (value, record) => {
      return (
        <div style={{ textAlign: 'right' }}>
          {record.paidAmount?.toLocaleString()}원
        </div>
      );
    },
  },
];

const TabSubscriptionHistory = () => {
  const { data: history, refetch: refetchHistory } = useQuery(
    ['subscriptionHistory'],
    () => TeambinderApiService.getPaymentHistory({ page: 1, pageSize: 100 }),
    { refetchInterval: 3000 },
  );
  const { data: currentPlan, refetch: refetchCurrentPlan } = useQuery(
    ['currentPlan'],
    () => TeambinderApiService.getLatestSubscription({ q: 'latest' }),
  );
  const cancelSubscription = async () => {
    confirm({
      title: '구독을 취소하시겠습니까?',
      okText: '구독 유지',
      cancelText: '구독 취소',
      async onCancel() {
        const run = async () => {
          await TeambinderApiService.changeSubscription({
            requestBody: {
              subscriptionPlan: 'FREE',
              billingCycle: 'INFINITE',
            },
          });
          setTimeout(() => {
            refetchHistory();
            refetchCurrentPlan();
          }, 1000);
        };
        run();
        setTimeout(() => {
          info({
            title: '구독이 취소되었습니다.',
          });
        }, 500);
      },
    });
  };

  return (
    <div style={{ position: 'relative' }}>
      <Table
        columns={columns}
        dataSource={history?.data || []}
        pagination={false}
        locale={{
          emptyText: (
            <p style={{ marginBottom: 0 }}>
              구독 내역이 존재하지 않습니다. <br />
              플랜을{' '}
              <Link to="/app/my?tab=2" style={{ textDecoration: 'underline' }}>
                업그레이드
              </Link>
              하면{' '}
              {Math.random() < 0.5
                ? '더 많은 알림톡을 받아보실 수 있어요!'
                : '더 많은 키워드를 등록하실 수 있어요!'}
            </p>
          ),
        }}
      />
      {currentPlan?.data?.plan?.subscriptionPackage?.subscriptionPlan !==
        'FREE' && (
        <Button
          onClick={cancelSubscription}
          style={{
            fontSize: '14px',
            borderRadius: '12px',
            backgroundColor: '#24C185',
            height: '42px',
            color: 'white',
            marginTop: '30px',
            position: 'absolute',
            right: '8px',
          }}
        >
          구독 취소
        </Button>
      )}
    </div>
  );
};

export default TabSubscriptionHistory;
